import { useEffect, useState } from 'react'
import useExchangeRate from 'hooks/useExchangeRate'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { WalletDetails } from 'components/Account/WalletTransfer'
import greenTick from '/assets/Icon/GreenTick.svg'
import redCross from '/assets/Icon/RedCross.svg'

interface CollectibleTransferCardProps {
  title?: string
  wallets: WalletDetails[]
  setToAddress?: Function
}

function CollectibleTransferCard({
  title,
  wallets,
  setToAddress
}: CollectibleTransferCardProps) {
  const [selectedWallet, setSelectedWallet] = useState(wallets[0]?.address)

  useEffect(() => {
    if (setToAddress) setToAddress(wallets[0]?.address)
  }, [setToAddress, wallets])

  const selectedWalletDetails = wallets.find(
    (w) => w?.address === selectedWallet
  )
  const totalCollectiblesInAccount = selectedWalletDetails?.collectibles ?? 0
  const forSaleCollectiblesInAccount =
    selectedWalletDetails?.forSaleCollectibles ?? 0
  const notForSaleCollectiblesInAccount =
    selectedWalletDetails?.notForSaleCollectibles ?? 0
  const walletBalance = selectedWalletDetails?.balance ?? 0

  const { rate } = useExchangeRate()

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedWallet(event.target.value as string)
    if (setToAddress) setToAddress(event.target.value)
  }

  const IconComponent = (props: { className: string }) => {
    return <KeyboardArrowDownIcon className={props.className} />
  }

  const menuProps = {
    PaperProps: {
      style: {
        backgroundColor: '#1A1A1A'
      }
    },

    MenuListProps: {
      classes: {
        root: 'menu-list-root'
      },

      sx: {
        backgroundColor: '#1A1A1A',
        '.MuiMenuItem-root': {
          '&:hover': {
            backgroundColor: '#252525'
          },
          '&.Mui-selected': {
            backgroundColor: '#292929'
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#2f2f2f'
          }
        }
      }
    }
  }

  const GreenTick = () => {
    return <img src={greenTick} alt="Green Tick" className="w-4" />
  }

  const RedCross = () => {
    return <img src={redCross} alt="Red Cross" className="w-4" />
  }

  return (
    <div className="flex flex-col gap-2 justify-between p-4 w-full bg-black1 rounded-[3px]">
      <div className="flex flex-col gap-2 w-full">
        <p className="text-xs font-bold uppercase">{title}</p>
        <div className="relative">
          <FormControl
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#1A1A1A'
                },
                '&:hover fieldset': {
                  borderColor: '#1A1A1A'
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1A1A1A'
                }
              }
            }}
          >
            {wallets.length > 1 ? (
              <Select
                value={selectedWallet}
                onChange={handleChange}
                displayEmpty
                IconComponent={IconComponent}
                sx={{
                  '& .MuiSelect-outlined': {
                    backgroundColor: '#1A1A1A',
                    borderRadius: '3px',
                    padding: '6px 16px 6px 6px'
                  },
                  '& .MuiSelect-icon': {
                    color: '#ffffff'
                  }
                }}
                MenuProps={menuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {wallets.map((wallet, index) => (
                  <MenuItem key={index} value={wallet?.address}>
                    <div className="flex gap-2 items-center p-2">
                      <img
                        src={wallet?.icon}
                        alt={`Logo ${wallet?.name}`}
                        className="w-[20px] h-6"
                      />
                      <div className="text-base text-white">{wallet?.name}</div>
                      <p className="text-grey2">
                        {shortenAddress(wallet?.address)}
                      </p>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <div className="flex gap-2 items-center py-[14px] px-4 bg-[#1A1A1A] rounded-[3px]">
                <img
                  src={wallets[0]?.icon}
                  alt={`Logo ${wallets[0]?.name}`}
                  className="w-[20px] h-6"
                />
                <div className="text-base text-white">{wallets[0]?.name}</div>
                <p className="text-grey2">
                  {shortenAddress(wallets[0]?.address)}
                </p>
              </div>
            )}
          </FormControl>
        </div>
        <div className="flex flex-col gap-2 items-start py-2 w-full">
          <div className="flex gap-2 justify-between items-start w-full">
            <div className="flex flex-col items-start">
              {/* <div className="text-sm font-bold">AFL Mint Collectibles</div> */}
              <div className="flex items-center text-sm font-bold">
                <GreenTick />
                <span className="pl-2">Collectibles (not for sale)</span>
              </div>
              <div className="flex items-center mt-2 text-sm font-bold">
                <RedCross />
                <span className="pl-2">Collectibles (for sale)</span>
              </div>
            </div>
            <div className="flex flex-col items-end text-sm text-right">
              {/* <div className="font-bold">{totalCollectiblesInAccount}</div> */}
              <div className="font-bold">{notForSaleCollectiblesInAccount}</div>
              <div className="mt-2 font-bold">
                {forSaleCollectiblesInAccount}
              </div>
            </div>
          </div>
          <div className="flex gap-2 justify-between items-start w-full">
            <div className="flex flex-col items-start">
              <div className="flex text-sm font-bold">
                <GreenTick />
                <span className="pl-2">Balance</span>
              </div>
            </div>
            <div className="flex flex-col items-end text-sm text-right">
              <div className="font-bold">
                A${Number(rate * walletBalance).toFixed(2)}
              </div>
              <div className="text-grey2">
                ({Number(walletBalance).toFixed(2)} USDC E)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function shortenAddress(address: string) {
  if (!address) return ''
  const start = address.slice(0, 6)
  const end = address.slice(-4)

  return (
    <span>
      {start}...{end}
    </span>
  )
}

export default CollectibleTransferCard
