import { Grid, Modal, Slide } from '@mui/material'
import { Add } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import Backdrop from '@mui/material/Backdrop'
import WalletPaymentCard from 'components/Card/WalletPaymentCard'
import CreditCardPaymentCard from 'components/Card/CreditCardPaymentCard'
import PaymentProviderCard from 'components/Card/PaymentProviderCard'
import banxa from '/assets/Icon/banxa-logo.svg'
import moonpay from '/assets/Icon/moonpay.png'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

export interface PaymentModalProps {
  anchorEl: null | HTMLElement
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  packPrice?: string
  packId?: string
  updateActiveStep?: number
  checkoutType: 'nft' | 'funds'
}

const PaymentModal = ({
  anchorEl,
  setAnchorEl,
  packPrice,
  packId,
  updateActiveStep,
  checkoutType = 'nft'
}: PaymentModalProps) => {
  const [_checkoutType, setCheckoutType] = useState<'nft' | 'funds'>(
    checkoutType
  )
  const [activeStep, setActiveStep] = React.useState(1)
  const open = Boolean(anchorEl)
  const {
    isBanxaEnabled,
    isBanxaTopUpEnabled,
    isMoonpayEnabled,
    isMoonpayTopUpEnabled,
    isCardPaymentsEnabled
  } = useFeatureFlags()

  useEffect(() => {
    if (updateActiveStep) {
      setActiveStep(updateActiveStep)
    }
    if (checkoutType) {
      setCheckoutType(checkoutType)
    }
  }, [updateActiveStep, checkoutType])

  const handleClosePaymentModal = () => {
    if (checkoutType === 'nft') setActiveStep(1)
    setAnchorEl(null)
  }

  const handleBackClick = () => {
    setActiveStep(1)
    if (checkoutType === 'nft') setCheckoutType('nft')
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClosePaymentModal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        className="w-full min-h-[100%] modal-bg"
      >
        <Slide direction="left" in={open}>
          <div className="absolute right-0 p-6 w-[340px] min-w-[340px] min-h-[100%] text-center text-white bg-black1">
            <div
              onClick={handleClosePaymentModal}
              className="flex absolute top-0 left-[-30px] justify-center items-center w-[30px] h-[30px] bg-white cursor-pointer"
            >
              <Add
                fontSize="large"
                style={{
                  transform: `rotate(45deg)`,
                  color: '#000000'
                }}
              />
            </div>
            <div className="flex flex-col gap-4">
              {activeStep === 1 || activeStep === 2 ? (
                <div className="flex flex-col">
                  <Grid
                    item
                    xs={12}
                    pb={2}
                    className={`flex ${activeStep === 1 ? 'justify-end' : 'justify-between'
                      } items-center w-full`}
                  >
                    {activeStep === 2 && checkoutType === 'nft' && (
                      <div
                        onClick={handleBackClick}
                        className="text-xs font-bold !leading-[18px] text-grey3 cursor-pointer"
                      >
                        Back
                      </div>
                    )}
                    <div
                      onClick={handleClosePaymentModal}
                      className="text-xs font-bold !leading-[18px] cursor-pointer"
                    >
                      Cancel
                    </div>
                  </Grid>
                  <div className="flex flex-col gap-2 justify-center items-center">
                    <p className="text-[10px] font-bold tracking-widest text-grey3 uppercase">
                      {_checkoutType === 'nft' ? 'Purchase' : 'Add Funds'}
                    </p>
                    <p className="text-2xl font-bold leading-none">
                      Payment {activeStep === 1 ? 'method' : 'provider'}
                    </p>
                    <p className=" text-xs">
                      {activeStep === 1
                        ? 'Buy instantly with your wallet funds or use your credit or debit card to check out.'
                        : 'Select a provider to continue. You’ll be securely taken to their app without leaving AFL Mint.'}
                    </p>
                  </div>
                </div>
              ) : null}
              {activeStep === 1 && (
                <>
                  <WalletPaymentCard
                    packPrice={packPrice}
                    packId={packId}
                    setActiveStep={setActiveStep}
                    setCheckoutType={setCheckoutType}
                    closePaymentModal={handleClosePaymentModal}
                  />
                  {isCardPaymentsEnabled && (
                    <CreditCardPaymentCard setActiveStep={setActiveStep} />
                  )}
                </>
              )}
              {activeStep === 2 && (
                <>
                  <PaymentProviderCard
                    packPrice={packPrice}
                    packId={packId}
                    isBanxaEnabled={isBanxaEnabled}
                    isBanxaTopUpEnabled={isBanxaTopUpEnabled}
                    isMoonpayEnabled={isMoonpayEnabled}
                    isMoonpayTopUpEnabled={isMoonpayTopUpEnabled}
                    checkoutType={_checkoutType}
                    setActiveStep={setActiveStep}
                    handleClosePaymentModal={handleClosePaymentModal}
                  />
                  <div className="text-xs text-grey3">
                    Credit card purchases may incur your bank’s cash advance
                    fee, subject to your bank’s policies. Debit card purchases
                    are more likely to be accepted by some banks.
                  </div>
                </>
              )}
              {activeStep === 3 && (
                <div className="flex flex-col">
                  <div
                    onClick={() => setActiveStep(2)}
                    className="flex self-start pb-2 w-full text-xs font-bold !leading-[18px] text-grey3 cursor-pointer"
                  >
                    Back
                  </div>
                  <div className="flex flex-col gap-4 justify-center items-center">
                    <img src={banxa} alt={`Provider icon`} className={`h-4`} />
                    <div className="flex gap-2 text-xs leading-none">
                      <a
                        href="https://banxa.com/"
                        className="text-blue3 underline hover:no-underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        banxa.com
                      </a>
                      <a
                        href="https://banxa.com/help-centre/"
                        className="text-blue3 underline hover:no-underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Banxa support
                      </a>
                    </div>
                    <p className="text-xs text-left">
                      Banxa is a leading Web3 on-and-off ramp solution for
                      payments. Banxa provides FIAT to USDC E account top-ups for
                      buying direct and bank card payments. Banxa will at random
                      intervals require Identity verification (KYC) from buyers
                      when using the AFL Mint website.{' '}
                    </p>
                  </div>
                </div>
              )}
              {activeStep === 4 && (
                <div className="flex flex-col">
                  <div
                    onClick={() => setActiveStep(2)}
                    className="flex self-start pb-2 w-full text-xs font-bold !leading-[18px] text-grey3 cursor-pointer"
                  >
                    Back
                  </div>
                  <div className="flex flex-col gap-4 justify-center items-center">
                    <img
                      src={moonpay}
                      alt={`Provider icon`}
                      className={`h-5`}
                    />
                    <div className="flex gap-2 text-xs leading-[18px]">
                      <a
                        href="https://www.moonpay.com/"
                        className="text-blue3 underline hover:no-underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        moonpay.com
                      </a>
                      <a
                        href="https://support.moonpay.com/hc/en-gb"
                        className="text-blue3 underline hover:no-underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Moonpay support
                      </a>
                    </div>
                    <p className="text-xs leading-[18px] text-left">
                      MoonPay is a financial technology company that builds
                      payments infrastructure for crypto. Our on-and-off-ramp
                      suite of products provides a seamless experience for
                      converting between fiat currencies and cryptocurrencies
                      using all major payment methods including debit and credit
                      card, local bank transfers, Apple Pay, Google Pay, and
                      Samsung Pay.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Slide>
      </Modal>
    </>
  )
}

export default PaymentModal
