import { useEffect, useState } from 'react'
import * as fcl from '@blocto/fcl'
import { useTransactions } from 'providers/TransactionsProvider'
import { useWallets } from 'providers/WalletsProvider'
import { CREATE_EVM_ADDRESS_TX } from 'flow/transactions/createEVMAddress.tx'
import { GET_EVM_ADDRESS } from 'flow/scripts/getEVMAddress.script'
import { BRIDGE_TOKENS_FROM_EVM_TX } from 'flow/transactions/bridgeTokensFromEVM.tx'
import { GET_EVM_BALANCE } from 'flow/scripts/getEVMUSDCBalance.script'
import { GET_ASSOCIATED_EVM_ADDRESS } from 'flow/scripts/getAssociatedEVMAddress.script'

export default function useEVM() {
  const [magicEVMAddress, setMagicEVMAddress] = useState<string | null>(null)
  const [bloctoEVMAddress, setBloctoEVMAddress] = useState<string | null>(null)
  const [connectedEVMAddress, setConnectedEVMAddress] = useState<string | null>(
    null
  )
  const [evmUSDBalance, setEVMUSDCBalance] = useState<number | null>(null)
  const [status, setStatus] = useState<string | null>(null)

  const { addTx } = useTransactions()
  const {
    userAuth,
    connectedWalletAddress,
    magicWalletAddress,
    bloctoWalletAddress,
    isConnected
  } = useWallets()

  useEffect(() => {
    if (isConnected && connectedWalletAddress) {
      getEVMAddress(connectedWalletAddress)
    }
  }, [isConnected, connectedWalletAddress])

  useEffect(() => {
    if (connectedEVMAddress) {
      getBalanceOf().then(setEVMUSDCBalance).catch(console.error)
    }
  }, [connectedEVMAddress])

  async function getEVMAddress(flowAddress: string) {
    try {
      const response = await fcl.send([
        fcl.script(GET_EVM_ADDRESS),
        fcl.args([fcl.arg(flowAddress, fcl.t.Address)])
      ])
      const address = await fcl.decode(response)

      if (!address) {
        console.error("You don't have an EVM address yet.")
        return
      }

      const addressString =
        '0x' + Buffer.from(address.addressBytes).toString('hex')
      if (connectedWalletAddress === magicWalletAddress) {
        setMagicEVMAddress(addressString)
      } else if (connectedWalletAddress === bloctoWalletAddress) {
        setBloctoEVMAddress(addressString)
      }
      setConnectedEVMAddress(addressString)
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  async function createCadenceOwnedAccount() {
    if (!connectedWalletAddress) return

    const amountOfFlow = "0.0"

    try {
      const tx = await fcl.send([
        fcl.transaction(CREATE_EVM_ADDRESS_TX),
        fcl.args([fcl.arg(amountOfFlow, fcl.t.UFix64)]),
        fcl.payer(userAuth),
        fcl.proposer(userAuth),
        fcl.authorizations([userAuth]),
        fcl.limit(9999)
      ])
      addTx(tx.transactionId, 'Creating Flow EVM Wallet')
      await fcl.tx(tx).onceSealed()

      tx.events.forEach((event: any) => {
        const EVM_CONTRACT_ADDRESS = process.env.VITE_EVM_CONTRACT
        const CREATED_EVENT = `A.${EVM_CONTRACT_ADDRESS?.slice(
          2
        )}.EVM.CadenceOwnedAccountCreated`
        if (event.type === CREATED_EVENT) {
          const address = event.data.address
          if (connectedWalletAddress === magicWalletAddress) {
            setMagicEVMAddress(address)
          } else if (connectedWalletAddress === bloctoWalletAddress) {
            setBloctoEVMAddress(address)
          }
        }
      })
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  async function getAssociatedEVMAddress(typeIdentifier: string) {
    try {
      const response = await fcl.send([
        fcl.script(GET_ASSOCIATED_EVM_ADDRESS),
        fcl.args([fcl.arg(typeIdentifier, fcl.t.String)])
      ])
      return await fcl.decode(response)
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  async function getBalanceOf() {
    try {
      const usdcEVMAddress = await getAssociatedEVMAddress(
        `A.${import.meta.env.VITE_USDCFLOW_CONTRACT.slice(2)}.USDCFlow.Vault`
      )
      if (!usdcEVMAddress || !connectedWalletAddress) return null

      const response = await fcl.send([
        fcl.script(GET_EVM_BALANCE),
        fcl.args([
          fcl.arg(connectedEVMAddress, fcl.t.String),
          fcl.arg(usdcEVMAddress, fcl.t.String)
        ])
      ])
      return await fcl.decode(response)
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  async function bridgeTokensFromEVM(amount: number) {
    if (!connectedEVMAddress) {
      console.error('No EVM address found')
      return
    }

    const tokenContractAddress = import.meta.env.VITE_USDCFLOW_CONTRACT
    const tokenContractName = 'USDCFlow'

    try {
      const tx = await fcl.send([
        fcl.transaction(BRIDGE_TOKENS_FROM_EVM_TX),
        fcl.args([
          fcl.arg(tokenContractAddress, fcl.t.Address),
          fcl.arg(tokenContractName, fcl.t.String),
          fcl.arg(amount, fcl.t.UInt256)
        ]),
        fcl.payer(userAuth),
        fcl.proposer(userAuth),
        fcl.authorizations([userAuth]),
        fcl.limit(9999)
      ])
      addTx(tx.transactionId, 'Bridging tokens to EVM')
      await fcl.tx(tx).onceSealed()
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  async function transferFromEVM() {
    if (!evmUSDBalance) {
      console.error('No EVM Balance to transfer!')
      return
    }
    await bridgeTokensFromEVM(evmUSDBalance)
  }

  async function transferToEVM() {
    // console.log('transferToEVM')
  }

  return {
    bloctoEVMAddress,
    magicEVMAddress,
    connectedEVMAddress,
    customTxStatus: status,
    getEVMAddress,
    evmUSDBalance: evmUSDBalance && (evmUSDBalance / 1000000).toFixed(2),
    transferFromEVM,
    transferToEVM,
    createCadenceOwnedAccount
  }
}
